<template>
  <div class="container-fluid h-auto">
    <div class="row">
      <div class="col-12">
        <BreadCrumb
            :links="[
            {
              name: 'Email Manager'
            },
            {
              name: 'Email Thread Viewer',
              params: {
                uuid: $route.params.uuid
              }
            }
          ]"
        />
      </div>
      <div class="col-12">
        <div class="mb-4 text-right">
          <button class="btn btn-success" @click="handleEmail">
            Compose
          </button>
        </div>
        <div class="card mb-4">
          <div class="card-body">
            <AssignedEmailList
                :email-data="emailResponse?.data"
            />
          </div>
        </div>
        <div class="mb-4 text-right">
          <button class="btn btn-success" @click="handleEmail">
            Compose
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AssignedEmailList from "@/components/lists/AssignedEmailList";
import BreadCrumb from "@/components/navigation/BreadCrumb";

export default {
  name: "EmailThreadViewer",
  data: () => ({
    emailResponse: null
  }),
  components: {
    AssignedEmailList,
    BreadCrumb
  },
  computed: {
    ...mapGetters([
      "getEmailApplication",
    ])
  },
  methods: {
    ...mapActions(["fetchEmailThread"]),
    async fetchThread() {
      this.emailResponse = await this.fetchEmailThread(
          this.$route.params.uuid
      ).catch(err => console.log(err));
    },
    handleEmail() {
      this.$router.push({
        name: "Email Send Interface",
        params: {
          tpuuid: this.$route.params.uuid
        },
        query: {
          onlyBlankTemplate: false,
          replyEmail: this.getEmailApplication?.email,
          parent: this.$route.query.parent
        }
      });
    }
  },
  async beforeMount() {
    await this.fetchThread();
  },
};
</script>
<style lang="scss" scoped></style>
